import React, { Component} from 'react'
import PropTypes from 'prop-types'
import String from 'inflection'
import { connect } from 'react-redux'
import { fetchAssessment } from '../actions/serverResourceProgressBarActionCreators'
import { NOT_READY_STATES } from '../constants/serverResourceProgressBarConstants';

export default class ServerResourceProgressBar extends React.Component {
  static propTypes = {
    averageProvisioningTime: PropTypes.number,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.number,
    lifecycle: PropTypes.string,
    provisioningTime: PropTypes.number,
    provisioningStarted: PropTypes.string,
    refreshAssessmentInterval: PropTypes.number,
    refreshProgressBarInterval: PropTypes.number
  };

  static defaultProps = {
    refreshProgressBarInterval: 1000,
    refreshAssessmentInterval:  30000,
  }

  constructor(props) {
    super(props);

    let progressBarUpdateInterval = null;
    let assessmentUpdateInterval = null;
    let pc = this.calcPercentCompleted(this.props.provisioningTime, this.props.averageProvisioningTime)


    this.state = {
      loadedAt: Date.now(),
      offset: 0,
      percentCompleted: pc
    }
  };

  componentWillReceiveProps(nextProps, nextState) {
    if (this.props.lifecycle == 'not_ready' && nextProps.lifecycle == 'ready') {
      console.log("Lifecycle changes to 'ready'")
      location.reload()
    }

    this.setState({percentCompleted: this.calcPercentCompleted(nextProps.provisioningTime,
                                                        nextProps.averageProvisioningTime)})
  }

  componentDidMount() {
    this.refreshProgressBar()
    this.refreshAssessment()
  }

  componentWillUnmount() {
    this.stopRefreshProgressBar()
    this.stopRefreshAssessment()
  }

  refreshProgressBar() {
    if (!this.progressBarUpdateInterval) {
      this.progressBarUpdateInterval = setInterval(this.updateProgressBar.bind(this), this.props.refreshProgressBarInterval)
    }
  }

  stopRefreshProgressBar() {
    if (this.progressBarUpdateInterval) {
      clearInterval(this.progressBarUpdateInterval)
      this.progressBarUpdateInterval = null
    }
  }

  refreshAssessment() {
    if (!this.assessmentUpdateInterval) {
      this.assessmentUpdateInterval = setInterval(this.updateAssessment.bind(this), this.props.refreshAssessmentInterval)
    }
  }

  stopRefreshAssessment() {
    if (this.assessmentUpdateInterval) {
      clearInterval(this.assessmentUpdateInterval)
      this.assessmentUpdateInterval = null
    }
  }

  updateProgressBar() {
    let offset = this.calcOffset()
    let percentCompleted = this.calcPercentCompleted(this.props.provisioningTime,
                                                     this.props.averageProvisioningTime)
    this.setState({
      offset: offset,
      percentCompleted: percentCompleted,
    })
    if(this.props.error){
      this.stopRefreshProgressBar()
      this.stopRefreshAssessment()
    }
  }

  updateAssessment() {
    this.props.fetchAssessment(this.props.uuid, this.props.cat)
  }

  calcOffset() {
    return Date.now() - this.state.loadedAt
  }

  calcPercentCompleted = (provisioningTime, averageProvisioningTime) => {
    let percentComplete = 0

    if (this.props.provisioningStarted == null){
      provisioningTime = 0
    } else {
      provisioningTime = (Date.now() - new Date(this.props.provisioningStarted).getTime()) / 1000
    }

    if (provisioningTime == 0 || averageProvisioningTime == 0) {
      percentComplete = 0
    } else if (provisioningTime >= averageProvisioningTime) {
      percentComplete = 0.99
    } else {
      percentComplete = provisioningTime / averageProvisioningTime
    }
    return percentComplete
  };

  width = () => {
    return this.state.percentCompleted.toFixed(2) * 100 + "%";
  };

  render() {
    const percentCompleted = this.state.percentCompleted
    const cls = "progress-bar progress-bar-striped " + (this.props.error ? "progress-bar-secondary" : "progress-bar-primary")
    const width = Math.floor(percentCompleted * 100) + "%"
    const style = {margin: 0, padding: 0}
    const errorMessage = this.props.error && this.props.error_message ? "- Error: Please contact support" : ""
    const notReady =
  <div className="progress" style={style}>
        <div className={cls} role="progressbar"
             aria-valuenow={percentCompleted} aria-valuemin="0" aria-valuemax="100"
             style={{width: width, minWidth: "2em"}}>
          <span>{width} Built {errorMessage}</span>
        </div>
  </div>

    const ready =
    <div>
    <span className="label label-default">
      {String.titleize(String.humanize(this.props.assessmentState))}
    </span>
    </div>;

    const show_bar = NOT_READY_STATES.includes(this.props.assessmentState)
    return(
      <div>
  {show_bar ? notReady : ready}
      </div>
    )
  };
}
