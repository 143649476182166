import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import ServerResourceProgressBar from '../components/ServerResourceProgressBar';
import * as actions from '../actions/serverResourceProgressBarActionCreators';

const mapStateToProps = (state) => {
  return {
    averageProvisioningTime: state.assessment.averageProvisioningTime,
    error: state.assessment.error,
    errorMessage: state.assessment.errorMessage,
    id: state.assessment.id,
    assessmentState: state.assessment.state,
    provisioningTime: state.assessment.provisioningTime,
    refreshAssessmentInterval: state.assessment.refreshAssessmentInterval,
    refreshProgressBarInterval: state.assessment.refreshProgressBarInterval,
    uuid: state.assessment.uuid,
    provisioningStarted: state.assessment.provisioningAt,
    cat: state.assessment.cat
  };
}

export default connect(mapStateToProps, actions)(ServerResourceProgressBar);
