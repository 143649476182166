import React from 'react'
import { Provider } from 'react-redux'

import configureStore from './store/serverResourceProgressBarStore';
import ServerResourceProgressBarContainer from './containers/ServerResourceProgressBarContainer';

const ServerResourceProgressBarApp = (props) => (
  <Provider store={configureStore(props)}>
    <ServerResourceProgressBarContainer/>
  </Provider>
);

export default ServerResourceProgressBarApp;
