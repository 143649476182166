import { combineReducers } from 'redux';
import { REQUEST_ASSESSMENT,
         RECEIVE_ASSESSMENT } from '../constants/serverResourceProgressBarConstants';

const assessment = (state = {isFetching: false}, action) => {
  switch (action.type) {
    case REQUEST_ASSESSMENT:
      return Object.assign({}, state, {isFetching: true })
    case RECEIVE_ASSESSMENT:
      return Object.assign({}, state, {
        isFetching: false,
        loadedAt: action.loadedAt
      }, action.assessment)
    default:
      return state;
  }
};

const serverResourceProgressBarReducer = combineReducers({ assessment })
export default serverResourceProgressBarReducer;
