import ReactDOM from 'react-dom'
import ServerResourceProgressBarApp from '../src/server_resource_progress_bar'
import humps from 'humps'

document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.getElementsByClassName('server-resource-progress-bar')
  for (var i = 0; i < nodes.length; i++) {
    const assessment = humps.camelizeKeys(JSON.parse(nodes[i].getAttribute('data-assessment')))
    ReactDOM.render(ServerResourceProgressBarApp({assessment: assessment}), nodes[i])
  }
});
