import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk'
import thunkMiddleware from 'redux-thunk'
import createLogger from 'redux-logger'
import serverResourceProgressBarReducer from '../reducers/serverResourceProgressBarReducer';

let loggerMiddleware = createLogger()
let middleware = [thunkMiddleware]
if (process.env.NODE_ENV != 'production') {
  middleware = [...middleware, loggerMiddleware]
}

export default function configureStore(railsProps) {
  return createStore(
    serverResourceProgressBarReducer,
    railsProps,
    applyMiddleware(...middleware)
  )
}
