/* eslint-disable import/prefer-default-export */
import fetch from 'isomorphic-fetch'
import humps from 'humps'

import { REQUEST_ASSESSMENT } from '../constants/serverResourceProgressBarConstants';
import { RECEIVE_ASSESSMENT } from '../constants/serverResourceProgressBarConstants';

export function requestAssessment(uuid) {
  return {
    type: REQUEST_ASSESSMENT,
    id: uuid,
  }
}

export function receiveAssessment(uuid, assessment) {
  return {
    type: RECEIVE_ASSESSMENT,
    id: uuid,
    assessment: assessment,
    loadedAt: Date.now(),
  }
}


export function fetchAssessment(uuid, cat) {
  return (dispatch) => {
    dispatch(requestAssessment(uuid));
    let url = "/api/ui_v1/assessments/" + uuid
    if (typeof(cat) != "undefined" && cat != "" && cat != "null" && cat != null) {
      url = url + "?cat=" + cat
    }
    return fetch(url, {method: 'GET', credentials: 'include'})
                  .then((response) => {
      if (!response.ok) {
      }
      return response;
                  })
                  .then((response) => response.json())
                  .then((json) => {
      dispatch(receiveAssessment(uuid, humps.camelizeKeys(json.assessment)))
      })
  }
}
